import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/tlarson/dev/home/familyhistories/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "sharing-family-histories"
    }}>{`Sharing Family Histories`}</h1>
    <p>{`Collaborate on family history documentation.`}</p>
    <p>{`Here are the easiest ways to begin:`}</p>
    <ul>
  <li>Watch <a href='https://www.familysearch.org/rootstech/rtc2021/session/the-global-genealogical-forest-with-a-distributed-network-of-roots'>a 20-minute technical presentation from RootsTech</a>.</li>
  <li>Run the <a href='javascript:  var scriptElem=document.createElement("script");  scriptElem.setAttribute("type","text/javascript");  scriptElem.setAttribute("src","http://familyhistories.info/static/tools/activate-links-bookmarklet/setup.js");  document.body.appendChild(scriptElem);  void(0);'>"activate ancestry links" bookmarklet</a> on sample files like <a href="http://ec2-34-228-224-89.compute-1.amazonaws.com/tolman-histories/Aaron_Bracken.htm">this one</a>. (If you're not familiar with bookmarklets, <a href="https://www.youtube.com/watch?v=VAfdGHnTFIQ">this video</a> explains how to install it.) </li>
  <li>Subscribe to <a href="/src-pages-examples">the Tolman family files</a>.</li>
    </ul>
    <p>{`The links in the menu explain other ideas and ways to start searching and sharing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      